<template>
  <InformationAppModal :value="shouldShowDuplicateQuoteWarning" icon="warningYellow">
    <h1 class="title">{{ $t('DuplicatedQuoteWarning.TitleText').value }}</h1>
    <!-- <h1 class="title">Are you sure you want to send this transfer again?</h1> -->
    <p>{{ $t('DuplicatedQuoteWarning.ParagraphText').value }}</p>

    <!-- <p>We noticed you recently sent a similar transfer.</p> -->

    <template #footer>
      <AppButton @click="onContinueClick">
        {{ $t('DuplicatedQuoteWarning.ContinueButtonText').value }}
      </AppButton>
      <AppButton theme="secondary" @click="closeDialog">
        {{ $t('DuplicatedQuoteWarning.CancelButtonText').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'

import { AppButton } from '@oen.web.vue2/ui'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'
import sendMoneySteps from '@galileo/constants/sendMoney/sendMoneySteps'

import { useI18nStore, useSendMoneyStore, useAnalyticsStore } from '@galileo/stores'

export default defineComponent({
  components: {
    InformationAppModal,
    AppButton,
  },
  setup(props, { emit }) {
    const analyticsStore = useAnalyticsStore()
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()

    const shouldShowDuplicateQuoteWarning = computed(() => sendMoneyStore.showDuplicateQuoteWarning)

    const closeDialog = () => {
      sendMoneyStore.hideDuplicateQuoteWarning()
      analyticsStore.track({
        event: SEGMENT_EVENTS.QUOTE_DUPLICATED_WARNING_CANCELLED,
      })
    }

    const onContinueClick = () => {
      emit('submit', sendMoneyStore.hideDuplicateQuoteWarning())
      analyticsStore.track({
        event: SEGMENT_EVENTS.QUOTE_DUPLICATED_WARNING_PROCEEDED,
      })
    }

    return { shouldShowDuplicateQuoteWarning, closeDialog, onContinueClick, $t }
  },
})
</script>

<style scoped></style>
