<template>
  <div>
    <AppCard>
      <template #header>
        <XeStepperCardHeader
          analytics-name="xe-send-money-amount"
          :title="$t('PageSendMoneyAmount.CountrySelectPageTitle').value"
        />
      </template>

      <AppInputCountry
        v-model="countryTo"
        :empty-filter-message="$t('AddressSearch.noResult').value"
        :should-reset-filter-on-focus="true"
        analytics-name="send-money-amount-country-select"
        :countries="countries"
        :label="$t('PageSendMoneyAmount.LabelCountry').value"
        :placeholder="$t('PageSendMoneyAmount.PlaceholderCountry').value"
        :country-name-after-icon="true"
        :filter-placeholder="$t('PageSendMoneyAmount.FilterPlaceholderCountry').value"
        class="country-select"
      />

      <AppButton
        :disabled="!countryTo"
        :loading="loading"
        data-testid="send-money-amount-country-select-button"
        @click="onCountrySelect"
      >
        {{ $t('PageSendMoneyAmount.CountrySelectButtonText').value }}
      </AppButton>
    </AppCard>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'

import { AppCard, AppInputCountry, AppButton } from '@oen.web.vue2/ui'

import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import { useSendMoneyStore, useI18nStore, useAnalyticsStore } from '@galileo/stores'

export default {
  name: 'SendMoneyCountrySelect',
  emits: ['changeDestinationCountry'],
  components: {
    AppCard,
    XeStepperCardHeader,
    AppInputCountry,
    AppButton,
  },
  props: {
    countries: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const sendMoneyStore = useSendMoneyStore()
    const countryTo = ref('')

    analyticsStore.track({
      event: SEGMENT_EVENTS.DESTINATION_COUNTRY_STARTED,
    })

    const onCountrySelect = () => {
      sendMoneyStore.form.countryTo = countryTo.value
      emit('select')
    }
    return {
      $t,
      countryTo,
      onCountrySelect,
    }
  },
}
</script>
