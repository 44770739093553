<template>
  <InformationAppModal :value="shouldDisplayMessage" icon="warningYellow" @close="closeModal">
    <h1>{{ $t('ComponentDeliveryDetailsChangedWarning.ModalTitle').value }}</h1>
    <p>
      {{ $t('ComponentDeliveryDetailsChangedWarning.ModalText').value }}
    </p>
    <template #footer>
      <AppButton @click="closeModal">
        {{ $t('ComponentDeliveryDetailsChangedWarning.ButtonText').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { computed } from '@vue/composition-api'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'

import { AppButton } from '@oen.web.vue2/ui'
import { useI18nStore, useSendMoneyStore } from '@galileo/stores'

export default {
  components: {
    InformationAppModal,
    AppButton,
  },
  setup() {
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()

    let shouldDisplayMessage = computed(() => sendMoneyStore.showDeliveryDetailsChangedWarning)

    const closeModal = () => {
      sendMoneyStore.showDeliveryDetailsChangedWarning = false
    }

    return {
      shouldDisplayMessage,
      closeModal,
      $t,
    }
  },
}
</script>
