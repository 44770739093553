<template>
  <InformationAppModal
    :value="shouldDisplayMessage"
    button-title="Change Amount"
    @close="closeModal"
  >
    <h1 v-if="amountExceededMessage === 'SellAmountAboveCashDeliverySendingMaxAmount'">
      {{ $t('ComponentCPOAmountExceededWarning.WarningTitle').value }}
    </h1>
    <h1 v-else>{{ $t('ComponentCPOAmountExceededWarning.BuyAmountWarningTitle').value }}</h1>
    <p>
      {{
        $t('ComponentCPOAmountExceededWarning.WarningText', { maxAmount, exchangeCurrency }).value
      }}
    </p>
    <template #footer>
      <AppButton @click="closeModalChangeAmount">
        {{ $t('ComponentCPOAmountExceededWarning.ButtonChangeAmount').value }}
      </AppButton>
      <AppButton theme="secondary" @click="closeModal">
        {{ $t('ComponentCPOAmountExceededWarning.ButtonContinue').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { computed, ref } from '@vue/composition-api'

import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'

import { AppButton } from '@oen.web.vue2/ui'

import { DeliveryMethod } from '@galileo/models/Transaction/app'

import { useI18nStore, useSendMoneyStore } from '@galileo/stores'

export default {
  components: {
    InformationAppModal,
    AppButton,
  },
  setup() {
    const { $t } = useI18nStore()
    let maxAmount = ref(0)
    let exchangeCurrency = ref('')
    let amountExceededMessage = ref(false)
    const sendMoneyStore = useSendMoneyStore()

    let shouldDisplayMessage = computed(() => {
      let warning = sendMoneyStore.showCPOWarning
      const CPOunavailableReason = sendMoneyStore.CPOunavailableReason

      const codesNotToConsider = ['QUOTE052']

      if (
        !codesNotToConsider.includes(CPOunavailableReason?.code) &&
        CPOunavailableReason?.parameters
      ) {
        maxAmount.value = CPOunavailableReason.parameters.find(
          (parameter) => parameter.key === 'maxAmount'
        )?.value
        exchangeCurrency.value = CPOunavailableReason.parameters.find(
          (parameter) => parameter.key === 'ccyCode'
        )?.value
        amountExceededMessage.value = CPOunavailableReason.code
        return warning
      }
    })

    const closeModal = () => {
      sendMoneyStore.setShowCPOWarning(false)
    }
    const closeModalChangeAmount = () => {
      sendMoneyStore.setShowCPOWarning(false)
      sendMoneyStore.previousDeliveryMethod = DeliveryMethod.CashPickup.enumName
    }

    return {
      shouldDisplayMessage,
      maxAmount,
      exchangeCurrency,
      amountExceededMessage,
      closeModal,
      closeModalChangeAmount,
      $t,
    }
  },
}
</script>
