<template>
  <AppModal v-model="model" :title="$t('ComponentSendMoneyDeliveryMethodModal.Title').value">
    <template #header="{ dismiss }">
      <AppModalHeader>
        <template #default>{{ $t('ComponentSendMoneyDeliveryMethodModal.Header').value }}</template>
        <template #right>
          <AppBackButton analytics-name="send-delivery-method-modal-back" @click="dismiss" />
        </template>
      </AppModalHeader>
    </template>

    <AppList v-if="deliveryList.length">
      <RiaListItem
        v-for="method in deliveryList"
        :key="method.value"
        :analytics-name="'send-delivery-method-modal-item-' + method.value"
        @itemClick="onItemClick(method)"
      >
        <template #figure-icon>
          <Component :is="method.icon" />
        </template>

        <template #left>
          <AppListItemTitle>{{ method.text }}</AppListItemTitle>
          <AppListItemCaption v-if="method.rate">
            1 {{ currencyFrom }} =
            <span class="text-blue">{{ getDeliveryMethodRate(method) }} {{ currencyTo }}</span>
          </AppListItemCaption>
        </template>
      </RiaListItem>
    </AppList>
  </AppModal>
</template>

<script>
import { useI18nStore } from '@galileo/stores'

import RiaListItem from '@galileo/components/RiaListItem/RiaListItem'
import { computed } from '@vue/composition-api'

import { IconPurchase, IconFinance, IconMoney, IconHome } from '@oen.web.vue2/icons'

import {
  useVModel,
  AppModal,
  AppModalHeader,
  AppBackButton,
  AppList,
  AppListItemCaption,
  AppListItemTitle,
} from '@oen.web.vue2/ui'

export default {
  name: 'SendMoneyPaymentDeliveryMethodModal',
  components: {
    RiaListItem,
    AppModal,
    AppModalHeader,
    AppBackButton,
    AppList,
    AppListItemTitle,
    AppListItemCaption,
    IconPurchase,
    IconFinance,
    IconMoney,
    IconHome,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    deliveryMethod: {
      type: String,
      default: '',
    },
    deliveryMethods: {
      type: Array,
      default: () => [],
      required: true,
    },
    currencyFrom: {
      type: String,
      default: '',
    },
    currencyTo: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)

    const getIcon = (value) => {
      let icon = 'IconMoney'

      if (value === 'BankDeposit') {
        icon = 'IconFinance'
      } else if (value === 'HomeDelivery') {
        icon = 'IconHome'
      }
      return icon
    }

    const getDeliveryMethodRate = (deliveryMethod) => {
      return parseFloat(deliveryMethod.rate.toString()).toFixed(2)
    }

    const deliveryList = computed(() => {
      return [
        ...props.deliveryMethods.map((method) => {
          return {
            //transform original deliveryMethods props to include icon
            ...method,
            icon: getIcon(method.value),
          }
        }),
      ]
    })

    const onItemClick = (paymentMethod) => {
      emit('update:delivery-method', paymentMethod.value)
      emit('input', false)
    }

    return {
      model,
      getIcon,
      getDeliveryMethodRate,
      deliveryList,
      $t,
      onItemClick,
    }
  },
}
</script>
