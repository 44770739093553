<template>
  <AppModal v-model="model" :title="$t('ComponentSendMoneyPaymentMethodModal.Title').value">
    <template #header="{ dismiss }">
      <AppModalHeader>
        <template #default>{{ $t('ComponentSendMoneyPaymentMethodModal.Header').value }}</template>
        <template #right>
          <AppBackButton analytics-name="send-payment-method-modal-back" @click="dismiss" />
        </template>
      </AppModalHeader>
    </template>

    <AppList v-if="paymentList.length">
      <RiaListItem
        v-for="method in paymentList"
        :key="method.value"
        :analytics-name="'send-payment-method-modal-item-' + method.value"
        :title="method.text"
        @itemClick="onItemClick(method)"
      >
        <template #figure-icon>
          <Component :is="method.icon" />
        </template>
      </RiaListItem>
    </AppList>
  </AppModal>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import { computed } from '@vue/composition-api'

import RiaListItem from '@galileo/components/RiaListItem/RiaListItem'

import { IconPurchase, IconFinance, IconMoney } from '@oen.web.vue2/icons'

import { useVModel, AppModal, AppModalHeader, AppBackButton, AppList } from '@oen.web.vue2/ui'

export default {
  name: 'SendMoneyPaymentMethodModal',
  components: {
    AppModal,
    AppModalHeader,
    AppBackButton,
    AppList,
    IconPurchase,
    IconFinance,
    IconMoney,
    RiaListItem,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    paymentMethod: {
      type: String,
      default: '',
    },
    paymentMethods: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)

    const getIcon = (value) => {
      let icon = 'IconMoney'

      if (value === 'BankAccount') {
        icon = 'IconFinance'
      } else if (value === 'CreditCard' || value === 'DebitCard') {
        icon = 'IconPurchase'
      }
      return icon
    }

    const paymentList = computed(() => {
      return [
        ...props.paymentMethods.map((method) => {
          return {
            //transform original paymentMethods props to include icon
            ...method,
            icon: getIcon(method.value),
          }
        }),
      ]
    })

    const onItemClick = (paymentMethod) => {
      emit('update:payment-method', paymentMethod.value)
      emit('input', false)
    }

    return {
      model,
      getIcon,
      paymentList,
      $t,
      onItemClick,
    }
  },
}
</script>
