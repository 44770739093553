<template>
  <div class="send-money-amount">
    <!--Send Money Form-->
    <AccountRestricted v-if="accountIsRetricted" />
    <SendMoneyCountrySelect
      v-else-if="!showQuoteScreen"
      :countries="countries"
      @select="onCountryToSelected"
      :loading="quoteScreenLoading"
    />

    <AppCard v-else-if="!isCountryToLoading">
      <template #header>
        <XeStepperCardHeader
          analytics-name="xe-send-money-amount"
          :title="activeStep.pageTitle"
          :back-button-hidden="summaryEdit"
        />
      </template>

      <form id="sendMoneyAmount" @submit.prevent="submit.exec">
        <AppAlert v-if="hasServerError" theme="yellow" class="mb-4">
          {{ $t('PageSendMoneyAmount.ServerError').value }}

          <AppAlertAction @click="hasServerError = null" />
        </AppAlert>
        <!--Destination-->
        <AppInputCountry
          :empty-filter-message="$t('AddressSearch.noResult').value"
          v-model="form.countryTo"
          should-reset-filter-on-focus
          :disabled="summaryEdit"
          analytics-name="send-money-amount-country"
          :countries="countries"
          :placeholder="$t('PageSendMoneyAmount.PlaceholderCountry').value"
          :pre-text="$t('PageSendMoneyAmount.PreTextCountry').value"
          :country-name-after-icon="false"
          :filter-placeholder="$t('PageSendMoneyAmount.FilterPlaceholderCountry').value"
          :validation="validation.countryTo"
          class="country-select"
          @input="changeDestinationCountry($event)"
        />

        <Transition name="fade">
          <div v-if="showQuoteScreen && form.countryTo">
            <!--Send Amount-->
            <AppInputAmountCurrency
              should-reset-filter-on-focus
              v-model="form.amountFrom"
              :disable-currency-selection="summaryEdit"
              analytics-name="send-money-amount-from"
              :class="{
                'formatted-input': true,
                'send-money-amount-currency': true,
                highlight: !shouldCalcAmountFrom && !focusedAmountTo,
              }"
              :label="$t('PageSendMoneyAmount.LabelAmountFrom').value"
              :loading="amountFromLoading"
              :currency.sync="form.currencyFrom"
              :currencies="currenciesFrom"
              :locale="userLocale"
              :validation="validation.amountFrom"
              :expand-currency-input="true"
              :filter-check-additional-field="true"
              additional-field-name="description"
              :filter-placeholder="$t('PageSendMoneyAmount.TypeToSearchPlaceholderText').value"
              :currency-label="$t('PageSendMoneyAmount.SelectCurrencyLabelText').value"
              :filter="true"
              :force-precision="true"
              :data-value="form.amountFrom"
              @focus="focusAmountFrom"
              @blur="blurAmountFrom"
            >
              <template #currency_selected="{ option }">
                <AppFlag class="country-flag">
                  <Component :is="getFlag(option.value)"></Component>
                </AppFlag>
                {{ option.value }}
              </template>
              <template #currency_option="{ option }">
                <AppFlag class="country-flag">
                  <Component :is="getFlag(option.value)"></Component>
                </AppFlag>
                <div
                  v-if="option.sameCurrencySupported && option.value === form.currencyTo"
                  class="option-text"
                >
                  <div>{{ sameCurrencyFromTradingText }}</div>
                  <span class="flag-new">{{ $t('PageSendMoneyAmount.FlagNewText').value }}</span>
                </div>
                <div v-else>
                  <span class="currency-text">{{ option.text }}</span> - {{ option.description }}
                </div>
              </template>
            </AppInputAmountCurrency>
            <SendMoneyQuoteErrorMessage />

            <!--Rate-->
            <AppDescriptionList v-if="shouldShowRate" class="rate">
              <SendMoneyRate
                :title="$t('PageSendMoneyAmount.RateText').value"
                :loading="calculator.loading"
                @infoDialog="feeInfoDialog"
              />
            </AppDescriptionList>

            <!--Receive Amount-->
            <AppInputAmountCurrency
              should-reset-filter-on-focus
              v-model="form.amountTo"
              :disable-currency-selection="summaryEdit"
              analytics-name="send-money-amount-to"
              :class="{
                'formatted-input': true,
                'send-money-amount-currency': true,
                'send-money-amount-helper-text': isAmountRounded,
                highlight: shouldCalcAmountFrom && !focusedAmountFrom,
              }"
              :label="$t('PageSendMoneyAmount.LabelAmountTo').value"
              :loading="amountToLoading"
              :currency.sync="form.currencyTo"
              :currencies="currenciesTo"
              :locale="userLocale"
              :validation="validation.amountTo"
              :filter="true"
              :filter-check-additional-field="true"
              additional-field-name="description"
              :filter-placeholder="$t('PageSendMoneyAmount.TypeToSearchPlaceholderText').value"
              :currency-label="$t('PageSendMoneyAmount.SelectCurrencyLabelText').value"
              :expand-currency-input="true"
              :helper-text="helperText"
              :force-precision="true"
              :data-value="form.amountTo"
              @focus="focusAmountTo"
              @blur="blurAmountTo"
            >
              <template #currency_selected="{ option }">
                <AppFlag class="country-flag">
                  <Component :is="getFlag(option.value)"></Component>
                </AppFlag>
                {{ option.value }}
              </template>
              <template #currency_option="{ option }">
                <AppFlag class="country-flag">
                  <Component :is="getFlag(option.value)"></Component>
                </AppFlag>
                <div
                  v-if="option.sameCurrencySupported && option.value === form.currencyFrom"
                  class="option-text"
                >
                  <div>{{ sameCurrencyToTradingText }}</div>
                  <span class="flag-new">{{ $t('PageSendMoneyAmount.FlagNewText').value }}</span>
                </div>
                <div v-else>
                  <span class="currency-text">{{ option.text }}</span> - {{ option.description }}
                </div>
              </template>
            </AppInputAmountCurrency>
            <!--Payment-->
            <!--    <Transition name="fade"> -->
            <div>
              <div class="col-span-1" :class="{ 'cursor-pointer': form.countryTo }">
                <AppMethodDropdown
                  v-model="form.paymentMethod"
                  class="methods payment-methods-dropdown"
                  analytics-name="send-money-amount-payment-method"
                  :placeholder="
                    form.paymentMethod
                      ? ''
                      : $t('PageSendMoneyAmount.PlaceholderPaymentMethod').value
                  "
                  :disabled="calculator.loading"
                  :validation="validation.countryTo"
                  :options="paymentMethods"
                  selected-display-field="text"
                  selected-value-field="value"
                  :option-pre-text="$t('PageSendMoneyAmount.PaymentMethodPreText').value"
                  :senderCountry="senderCountry"
                  @input="changePaymentMethod($event)"
                >
                  <template #option="{ option }">
                    <div class="method">
                      <div class="method-icons">
                        <IconCreditCard
                          v-if="option.value === 'DebitCard' || option.value === 'CreditCard'"
                          class="card-icon"
                        />
                        <IconACH
                          v-else-if="option.value === 'DirectDebit' && senderCountry === 'US'"
                          class="icon-payment-method"
                        />
                        <IconEFT
                          v-else-if="option.value === 'DirectDebit' && senderCountry === 'CA'"
                          class="icon-payment-method left-icon"
                        />

                        <IconBank
                          v-else-if="
                            option.value === 'BankAccount' ||
                            option.value === 'BankTransfer' ||
                            option.value === 'DirectDebit' ||
                            option.value === 'Interac'
                          "
                          class="bank-icon"
                        />
                        <IconBalances
                          v-if="option.value === PAYMENT_METHODS.FUNDS_ON_BALANCE"
                          class="balances-icon"
                        />

                        <IconPayTo
                          v-else-if="
                            option.value === PaymentMethod.OpenBanking.enumName && shouldUsePayTo
                          "
                          class="icon-payment-method"
                        />

                        <IconVolt
                          v-else-if="option.value === PaymentMethod.OpenBanking.enumName"
                          class="icon-payment-method"
                        />
                      </div>
                      <div class="transfer-methods">
                        <div class="method-title-and-fee">
                          <div class="transfer-methods-title">
                            <div
                              v-if="
                                option.value === PaymentMethod.OpenBanking.enumName &&
                                shouldUsePayTo
                              "
                            >
                              {{ $t('PageSendMoneyAmount.PayToOpenBankingDropdownText').value }}
                            </div>
                            <div
                              v-else-if="option.value === PaymentMethod.Interac.enumName"
                              class="interac-title"
                            >
                              <p>
                                {{ option.text }}
                              </p>
                              <IconInterac class="interac-icon" />
                            </div>
                            <div v-else>{{ option.text }}</div>

                            <AppIcon name=" " class="checkmark">
                              <IconCheckmark v-if="option.value === form.paymentMethod" />
                            </AppIcon>
                          </div>

                          <div class="transfer-methods-fee">
                            <div v-if="option.value === PAYMENT_METHODS.FUNDS_ON_BALANCE" />
                            <div
                              v-else-if="option.isAvailable && option.quote.paymentMethodFee"
                              class="transfer-methods-title"
                            >
                              {{
                                formatCurrencyWithSymbol(
                                  form.currencyFrom,
                                  option.quote.paymentMethodFee + option.quote.transferFee
                                )
                              }}
                            </div>
                            <div
                              v-else-if="option.isAvailable && option.quote.paymentMethodMarginFee"
                              class="transfer-methods-title"
                            >
                              {{
                                formatCurrencyWithSymbol(
                                  form.currencyFrom,
                                  option.quote.paymentMethodMarginFee + option.quote.transferFee
                                )
                              }}
                            </div>
                            <div
                              v-else-if="option.isAvailable && option.quote.transferFee"
                              class="transfer-methods-title"
                            >
                              {{
                                formatCurrencyWithSymbol(
                                  form.currencyFrom,
                                  option.quote.transferFee
                                )
                              }}
                            </div>

                            <div
                              v-else-if="
                                option.isAvailable &&
                                option.quote.paymentMethodFee + option.quote.transferFee === 0 &&
                                option.quote.paymentMethodMarginFee + option.quote.transferFee === 0
                              "
                              class="transfer-methods-title"
                            >
                              {{ formatCurrencyWithSymbol(form.currencyFrom, 0) }}
                            </div>
                          </div>
                        </div>

                        <div v-if="option.isAvailable" class="method-sub-text">
                          <template
                            v-if="option.value === 'BankAccount' || option.value === 'BankTransfer'"
                          >
                            {{ $t('PageSendMoneyAmount.ArrangeBankPayment').value }}
                          </template>
                          <template v-if="option.value === PAYMENT_METHODS.DIRECT_DEBIT">
                            {{ $t('PageSendMoneyAmount.ConnectYourBankAccount').value }}
                          </template>
                          <template v-if="option.value === PAYMENT_METHODS.FUNDS_ON_BALANCE">
                            {{ $t('PageSendMoneyAmount.BalanceSubText').value }}
                          </template>
                          <template
                            v-if="
                              option.value === PaymentMethod.OpenBanking.enumName && !shouldUsePayTo
                            "
                          >
                            {{ $t('SendMoneyPayment.OpenBankingDropdownDescriptionVolt').value }}
                            <BankLogos v-if="useBankLogos(option.value)" />
                          </template>
                          <template
                            v-if="
                              option.value === PaymentMethod.OpenBanking.enumName && shouldUsePayTo
                            "
                          >
                            {{ $t('SendMoneyPayment.OpenBankingDropdownDescriptionPayTo').value }}
                          </template>
                          <template v-if="option.value === PaymentMethod.Interac.enumName">
                            {{ $t('PageSendMoneyAmount.ArrangeBankPayment').value }}
                          </template>
                        </div>
                        <div
                          v-if="!option.isAvailable && !option.isAvailableForDeliveryMethod"
                          class="method-sub-text"
                        >
                          <p v-if="form.deliveryMethod === 'CashPayout'">
                            {{ $t('PageSendMoneyAmount.CashPickupNotAvailable').value }}
                          </p>
                          <p v-else-if="form.deliveryMethod === 'BankAccount'">
                            {{ $t('PageSendMoneyAmount.BankAccountNotAvailable').value }}
                          </p>
                          <p v-else-if="form.deliveryMethod === 'MobileWallet'">
                            {{ $t('PageSendMoneyAmount.MobileWalletNotAvailable').value }}
                          </p>
                          <p v-else>
                            {{ $t('PageSendMoneyAmount.PaymentMethodNotAvailable').value }}
                          </p>
                        </div>
                        <div v-else-if="!option.isAvailable" class="method-sub-text">
                          <p v-if="option.quote.errorText" v-html="option.quote.errorText" />
                          <p v-else>
                            {{ $t('PageSendMoneyAmount.GenericNotAvailable').value }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                </AppMethodDropdown>
              </div>
            </div>

            <!-- Delivery Method -->
            <div>
              <div class="col-span-1" :class="{ 'cursor-pointer': form.countryTo }">
                <AppMethodDropdown
                  v-model="form.deliveryMethod"
                  class="methods"
                  analytics-name="send-money-amount-delivery-method"
                  :placeholder="
                    form.deliveryMethod
                      ? ''
                      : $t('PageSendMoneyAmount.PlaceholderDeliveryMethod').value
                  "
                  :disabled="calculator.loading || summaryEdit"
                  :validation="validation.countryTo"
                  :options="deliveryMethods"
                  selected-display-field="text"
                  selected-value-field="value"
                  :option-pre-text="
                    form.deliveryMethod ? $t('PageSendMoneyAmount.DeliveryMethodPreText').value : ''
                  "
                  @input="changeDeliveryMethod($event)"
                  @click="openDeliveryMethodsDropdown()"
                  @blur="deliveryOnBlur()"
                  :disable-dropdown="isCorporateAccount || isBranded"
                  :show-arrow="!isCorporateAccount && !isBranded"
                >
                  <template #option="{ option }">
                    <div class="method">
                      <div class="method-icons">
                        <AppIcon name=" ">
                          <IconWallet v-if="option.value === 'CashPayout'" />
                          <IconFinance v-if="option.value === 'BankAccount'" />
                          <IconMobileWallet v-if="option.value === 'MobileWallet'" />
                        </AppIcon>
                      </div>
                      <div class="transfer-methods">
                        <div class="delivery-methods-wraper">
                          <div class="transfer-methods-title">
                            <div v-if="option.value === 'CashPayout'">
                              {{
                                $t('ComponentXeTransferDetails.DeliveryMethodOfficePickup').value
                              }}
                            </div>
                            <div v-if="option.value === 'BankAccount'">
                              {{ $t('ComponentXeTransferDetails.DeliveryMethodBankDeposit').value }}
                            </div>
                            <div v-if="option.value === 'MobileWallet'">
                              {{
                                $t('ComponentXeTransferDetails.DeliveryMethodMobileWallet').value
                              }}
                            </div>
                            <AppIcon name=" " class="checkmark">
                              <IconCheckmark v-if="option.value === form.deliveryMethod" />
                            </AppIcon>
                          </div>
                          <div v-if="option.value === 'MobileWallet'" class="method-flag-new">
                            {{ $t('PageSendMoneyAmount.FlagNewText').value }}
                          </div>
                        </div>
                        <div v-if="!option.isAvailable" class="method-sub-text">
                          {{ $t('PageSendMoneyAmount.GenericNotAvailable').value }}
                        </div>
                        <div v-else-if="option.value === 'BankAccount'" class="method-sub-text">
                          {{
                            $t('ComponentXeTransferDetails.DeliveryMethodBankDepositSubText').value
                          }}
                          <!-- All major banks -->
                        </div>
                        <div v-else-if="option.value === 'CashPayout'" class="method-sub-text">
                          {{
                            $t('ComponentXeTransferDetails.DeliveryMethodOfficePickupSubText').value
                          }}
                          <!-- Over 447.000 locations worldwide -->
                        </div>
                        <div v-else-if="option.value === 'MobileWallet'" class="method-sub-text">
                          {{
                            $t('ComponentXeTransferDetails.DeliveryMethodMobileWalletSubText').value
                          }}
                          <!-- Xe supports most major wallets -->
                        </div>
                      </div>
                    </div>
                  </template>
                </AppMethodDropdown>
              </div>
            </div>
            <!-- Promo code  -->
            <SendMoneyPromoCode
              v-if="promotionStore.getCanAccessPromotion()"
              :promo-disabled="
                calculator.loading || submit.loading || !!calculator.error || validation.$anyInvalid
              "
            />
            <!--Fee / Total-->
            <AppDescriptionList border-top>
              <!--Fees-->
              <SendMoneyTransferFees
                :title="$t('PageSendMoneyAmount.FeeText').value"
                :loading="calculator.loading"
                class="fees"
              >
                <template v-if="!shouldShowFee" #content>
                  <p>-</p>
                </template>
              </SendMoneyTransferFees>
              <AppDescriptionListItem v-if="deliveryTime">
                <template #title>
                  <div class="font-medium">
                    {{ $t('PageSendMoneyAmount.DeliveryTime').value }}
                  </div>
                </template>
                <template #content>
                  <LoadingTransition :tag="tag.default" :loading="calculator.loading">
                    <p>{{ deliveryTime }}</p>
                  </LoadingTransition>
                </template>
              </AppDescriptionListItem>
            </AppDescriptionList>
            <!--Total-->
            <AppDescriptionList>
              <AppDescriptionListItem class="transfer-total">
                <template #title>
                  <SendMoneyTransferTotal
                    :loading="calculator.loading"
                    :title="$t('PageSendMoneyAmount.TotalText').value"
                    analytics-name="send-money-amount-total"
                  >
                    <template v-if="!shouldShowTotalAmount" #content>
                      <p class="font-bold text-tertiary-text-dark">-</p>
                    </template>
                  </SendMoneyTransferTotal>
                </template>
                <template #content>
                  <AppButton
                    analytics-name="send-money-amount-submit"
                    :disabled="
                      calculator.loading ||
                      submit.loading ||
                      !!calculator.error ||
                      validation.$anyInvalid
                    "
                    type="submit"
                    :loading="submit.loading"
                  >
                    <span v-if="!summaryEdit">
                      {{ $t('PageSendMoneyAmount.ButtonContinue').value }}
                    </span>
                    <span v-else>
                      {{ $t('PageSendMoneyAmount.ButtonUpdate').value }}
                    </span>
                  </AppButton>
                </template>
              </AppDescriptionListItem>
            </AppDescriptionList>
          </div>
        </Transition>
      </form>
    </AppCard>
    <AppSpinnerBig :loading="isCountryToLoading"></AppSpinnerBig>
    <DuplicatedQuoteWarning @submit="submit.exec" />

    <!-- Payment method modal -->
    <SendMoneyPaymentMethodModal
      v-model="shouldShowPaymentMethodModal"
      :payment-method.sync="form.paymentMethod"
      :payment-methods="paymentMethods"
    />

    <!-- Delivery method modal -->
    <SendMoneyDeliveryMethodModal
      v-model="shouldShowDeliveryMethodModal"
      :delivery-method.sync="form.deliveryMethod"
      :delivery-methods="deliveryMethods"
      :currency-from="form.currencyFrom"
      :currency-to="form.currencyTo"
    />

    <XeBubble v-model="showFeeInfo" :dismissible="true">
      <div
        v-if="mouseEvent"
        class="xebubble"
        :style="{ top: mouseEvent.clientY + 'px', left: mouseEvent.clientX - 51 + 'px' }"
      >
        <div class="pointer"></div>
        <div class="bubble">{{ $t('PageSendMoneyAmount.FeeInfoText').value }}</div>
      </div>
    </XeBubble>

    <SendMoneyUsWireTransferWarning />
    <CPOAmountExceededWarning />
    <DeliveryDetailsChangedWarning />
    <RedirectToQuickTransfer v-model="isRedirectToQuickTransferModalOpen" />
    <CurrencyNotSupportedModal
      :value="currencyNotAllowed"
      @closeModal="currencyNotAllowed = false"
    />
    <ModalRouterView />
  </div>
</template>

<script>
import { DeliveryMethod, PaymentMethod } from '@galileo/models/Transaction/app'

import {
  onBeforeMount,
  ref,
  reactive,
  toRef,
  computed,
  watch,
  onMounted,
} from '@vue/composition-api'
import { useValidation, usePromiseLazy } from 'vue-composable'
import { required } from '@vuelidate/validators'
import { useRouter } from '@galileo/composables/useRouter'

import CurrencyNotSupportedModal from '@galileo/components/CurrencyNotSupported/CurrencyNotSupportedModal'
import ModalRouterView from '@galileo/views/ModalRouterView'

import { usePaymentName } from '../composables/usePaymentHelpers'
import AppMethodDropdown from '@galileo/components/AppMethodDropdown/AppMethodDropdown'
import XeBubble from '@galileo/components/XeBubble/XeBubble'

import { formatCurrencyToParts } from '@galileo/composables/useCurrency'
import { useBankLogos } from '@galileo/composables/useBankLogos'

import {
  DELIVERY_METHODS,
  PAYMENT_METHODS,
  QUOTE_SCREEN,
  TRANSFER,
  TRANSFER_TYPE,
} from '@galileo/constants/sendMoneyFlow.const.js'

import {
  AppFlag,
  AppModal,
  AppDialog,
  AppSpinnerBig,
  AppModalHeader,
  AppModalFooter,
  AppBackButton,
  AppButton,
  AppIcon,
  AppCard,
  AppDescriptionList,
  AppList,
  AppListItem,
  AppInputAmountCurrency,
  AppInputCountry,
  AppInputDropdown,
  AppAlert,
  AppAlertAction,
  AppDescriptionListItem,
} from '@oen.web.vue2/ui'

import {
  IconTag,
  IconCloseFilled,
  IconFinance,
  IconPurchase,
  IconCheckmark,
  IconWallet,
  IconInformation,
  IconMoneyBag,
} from '@oen.web.vue2/icons'

import {
  IconMobileWallet,
  IconVolt,
  IconPayTo,
  IconCreditCard,
  IconBank,
  IconACH,
  IconEFT,
  IconInterac,
} from '@galileo/assets/icons/vue'

import * as EuronetFlags from '@oen.web.vue2/flags'

import SendMoneyPaymentMethodModal from '@galileo/components/Views/SendMoney/SendMoneyPaymentMethodModal'
import SendMoneyDeliveryMethodModal from '@galileo/components/Views/SendMoney/SendMoneyDeliveryMethodModal'
import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'

import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

import {
  getSegmentPaymentMethod,
  getSegmentPayoutMethod,
} from '@galileo/composables/useSegmentHelpers'

import {
  SendMoneyRate,
  SendMoneyTransferFees,
  SendMoneyTransferTotal,
} from '@galileo/components/Views/SendMoney/Organisms'

import SendMoneyUsWireTransferWarning from '@galileo/components/Views/SendMoney/SendMoneyUsWireTransferWarning'
import RedirectToQuickTransfer from '@galileo/components/Views/SendMoney/RedirectToQuickTransfer'
import CPOAmountExceededWarning from '@galileo/components/Views/SendMoney/CPOAmountExceededWarning'
import DeliveryDetailsChangedWarning from '@galileo/components/Views/SendMoney/DeliveryDetailsChangedWarning'
import AccountRestricted from '@galileo/components/Views/SendMoney/Amount/AccountRestricted'
import SendMoneyCountrySelect from '@galileo/components/Views/SendMoney/Amount/SendMoneyCountrySelect'
import DuplicatedQuoteWarning from '@galileo/components/Views/SendMoney/Amount/DuplicatedQuoteWarning'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import { getAllInfoByCountry } from '@galileo/composables/useIsoCountryCurrencyLibrary'

import { storeToRefs } from 'pinia'

import { loading, tag } from '@galileo/components/Views/SendMoney/Atoms/-common-props'
import LoadingTransition from '@galileo/components/LoadingTransition/LoadingTransition'

import SendMoneyQuoteErrorMessage from '@galileo/components/Views/SendMoney/SendMoneyQuoteErrorMessage'
import SendMoneyPromoCode from '@galileo/components/Views/SendMoney/SendMoneyPromoCode'
import BankLogos from '@galileo/components/Views/SendMoney/SendMoneyBankLogos'

import {
  useI18nStore,
  useSendMoneyStore,
  useResourcesStore,
  usePaymentsStore,
  useDeviceStore,
  useAnalyticsStore,
  useRecipientsStore,
  useAppStore,
  useAuthStore,
  useThemeStore,
  usePromotionStore,
  useApiStore,
} from '@galileo/stores'
import { useKYCRefreshCorporateStore } from '@galileo/stores/kycRefreshCorporate'

import getValueRequiredValidation from '@galileo/utilities/validations.utility'
import useOpenBanking from '@galileo/composables/useOpenBanking'
import { IconBalances } from '@galileo/assets/icons/navMenu'
import { REQUEST_CANCELATION_TOKEN_ENUMS } from '@galileo/constants/requestCancelationTokens'

export default {
  name: 'SendMoneyAmount',
  components: {
    IconBalances,
    AppModalHeader,
    AppModalFooter,
    AppBackButton,
    XeBackButton,
    IconInformation,
    AppCard,
    AppDescriptionList,
    AppList,
    AppListItem,
    AppInputAmountCurrency,
    AppButton,
    AppInputCountry,
    AppInputDropdown,
    AppMethodDropdown,
    AppAlert,
    AppAlertAction,
    AppDescriptionListItem,
    AppIcon,
    IconTag,
    AppModal,
    AppDialog,
    IconCloseFilled,
    IconFinance,
    IconPurchase,
    IconCheckmark,
    IconWallet,
    SendMoneyPaymentMethodModal,
    SendMoneyDeliveryMethodModal,
    XeStepperCardHeader,
    SendMoneyRate,
    SendMoneyTransferFees,
    SendMoneyTransferTotal,
    AppFlag,
    XeBubble,
    SendMoneyUsWireTransferWarning,
    RedirectToQuickTransfer,
    CPOAmountExceededWarning,
    DeliveryDetailsChangedWarning,
    AccountRestricted,
    DuplicatedQuoteWarning,
    IconCreditCard,
    IconBank,
    IconACH,
    IconEFT,
    IconMobileWallet,
    SendMoneyCountrySelect,
    AppSpinnerBig,
    LoadingTransition,
    IconMoneyBag,
    SendMoneyQuoteErrorMessage,
    IconVolt,
    IconPayTo,
    IconInterac,
    CurrencyNotSupportedModal,
    SendMoneyPromoCode,
    ModalRouterView,
    BankLogos,
  },
  props: {
    filterPlaceholder: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()
    const { getTextKey } = usePaymentName()
    const accountIsRetricted = ref(false)
    const resourcesStore = useResourcesStore()
    const analyticsStore = useAnalyticsStore()
    const promotionStore = usePromotionStore()
    const appStore = useAppStore()
    const deviceStore = useDeviceStore()
    const recipientsStore = useRecipientsStore()
    const authStore = useAuthStore()
    const sendMoneyStore = useSendMoneyStore()
    const isBranded = ref(useThemeStore().isBranded)
    const valueRequired = getValueRequiredValidation()
    const isCountryToLoading = ref(true)
    const currencyNotAllowed = ref(false)
    const isRedirectToQuickTransferModalOpen = ref(false)
    const apiStore = useApiStore()

    const { shouldUsePayTo } = useOpenBanking()
    const { isCorporateAccount } = storeToRefs(authStore)

    // Amount Rounded Warning
    const isAmountRounded = computed(() => {
      if (form.deliveryMethod === 'CashPayout') {
        let cashPayoutMethod = deliveryMethods.value.find((method) => method.value === 'CashPayout')

        // Race condition happening in mutations
        let clonedCashPayout = Object.assign({}, cashPayoutMethod)

        if (clonedCashPayout && clonedCashPayout.quote) {
          return cashPayoutMethod.quote.amountRounded
        }
      }
      return false
    })

    const helperText = computed(() => {
      if (isAmountRounded.value && form.deliveryMethod === 'CashPayout') {
        return $t('PageSendMoneyAmount.HelperTextAmountRounded').value
      } else {
        return ''
      }
    })
    // End of Amount Rounded Warning

    const summaryEdit = ref(false)
    if (router.currentRoute.meta.summaryEdit) {
      summaryEdit.value = true
    }

    const focusedAmountFrom = ref(true)
    const focusedAmountTo = ref(false)

    const focusAmountFrom = () => {
      focusedAmountFrom.value = true
      blurAmountTo()
    }
    const blurAmountFrom = () => {
      focusedAmountFrom.value = false
    }

    const focusAmountTo = () => {
      focusedAmountTo.value = true
      blurAmountFrom()
    }
    const blurAmountTo = () => {
      focusedAmountTo.value = false
    }

    const activeStep = computed(() => sendMoneyStore.activeStep)
    const userLocale = computed(() => deviceStore.getLocale)

    const fees = computed(() => sendMoneyStore.getCalculatedFee(true))
    const transferFee = computed(() => sendMoneyStore.getTransferFee(true))
    const includedFee = computed(() => sendMoneyStore.getIsIncludedFee)
    const paymentMethodFee = computed(() => sendMoneyStore.getPaymentMethodFee(true))
    const currencyFrom = computed(() => sendMoneyStore.getCurrencyFrom)

    // ==== Calculate Method
    const calculator = reactive(
      usePromiseLazy(async (debounceTime) => {
        if (form.countryTo && form.currencyTo) {
          apiStore.abortApiRequest(REQUEST_CANCELATION_TOKEN_ENUMS.REFRESH_QUOTE)
          apiStore.abortApiRequest(REQUEST_CANCELATION_TOKEN_ENUMS.CALCULATE_NEW_QUOTE)
          await sendMoneyStore.calculate({
            debounceTime,
            focused: focusedAmountFrom.value ? QUOTE_SCREEN.AMOUNT_FROM : QUOTE_SCREEN.AMOUNT_TO,
          })
          sendMoneyStore.updatePaymentAndDeliveryMethods()
          fetchTransferBasicCall()
        }
      })
    )

    const showFeeInfo = ref(false)
    const mouseEvent = ref()
    const feeInfoDialog = (event) => {
      showFeeInfo.value = !showFeeInfo.value
      mouseEvent.value = event
    }
    const handleFeeDismiss = () => {
      showFeeInfo.value = false
    }

    const PROPERTY_CHANGED = {
      get DELIVERY_METHOD() {
        PROPERTY_CHANGED._deliveryMethodChanges++
        return {
          counter: PROPERTY_CHANGED._deliveryMethodChanges,
          name: 'delivery method',
        }
      },

      get PAYMENT_METHOD() {
        PROPERTY_CHANGED._paymentMethodChanges++
        return {
          counter: PROPERTY_CHANGED._paymentMethodChanges,
          name: 'payment method',
        }
      },

      _deliveryMethodChanges: 0,
      _paymentMethodChanges: 0,
    }

    const isSameCurrencySupported = authStore.userProfile.sameCurrencySupported

    const { availableCurrencies } = storeToRefs(sendMoneyStore)

    const currencyToFocus = ref(false)

    const isSameCurrencyAvailable = computed(() => {
      let pivot
      if (currencyToFocus.value) {
        pivot = form.currencyFrom
      } else {
        pivot = form.currencyTo
      }
      if (availableCurrencies.value?.length) {
        let selectedCurrency = availableCurrencies.value.find(
          (currency) => currency.value === pivot
        )
        return selectedCurrency?.sameCurrencySupported
      }
    })

    const getFirstFavCurrency = (selectedCurrency) => {
      return sendMoneyStore.getFirstFavoriteCurrency(selectedCurrency)
    }

    //store temporarly the countryTo so if we close the quote screen and reset the value in the store
    //there will always be a value to display and won't trigger any visual bugs
    let countryToTemp = null

    const form = reactive({
      countryTo: computed({
        get: () => {
          let countryToStore = sendMoneyStore.getCountryTo || countryToTemp
          return countryToStore
        },
        set: async (value) => {
          if (!value || value === sendMoneyStore.form.countryTo) return
          sendMoneyStore.calculatorSelectCountryTo(value)
          const newCurrencyTo = getAllInfoByCountry(value).currency
          // When selected currency is different from current
          if (
            form.currencyFrom !== newCurrencyTo ||
            (isSameCurrencySupported && isSameCurrencyAvailable.value)
          ) {
            sendMoneyStore.setCurrencyToForCountry(value)
          } else {
            // if currency from is the same as currency to don't swap it but select a new currencyTo
            const favoriteCurrency = getFirstFavCurrency(form.currencyFrom)
            sendMoneyStore.form.currencyTo = favoriteCurrency.value
          }
          if (sendMoneyStore.form.shouldCalcAmountFrom) {
            sendMoneyStore.form.amountFrom = null
          } else {
            sendMoneyStore.form.amountTo = null
          }
          await calculator.exec()
        },
      }),
      amountFrom: computed({
        get: () => sendMoneyStore.getAmountFrom(false),
        set: async (amount) => {
          sendMoneyStore.form.shouldCalcAmountFrom = false
          sendMoneyStore.form.amountFrom = amount
          //reset quoteError
          sendMoneyStore.form.quoteErrorFrom = null
          await calculator.exec()
        },
      }),
      currencyFrom: computed({
        get: () => sendMoneyStore.getCurrencyFrom,
        set: async (currency) => {
          if (currency === sendMoneyStore.form.currencyFrom) return

          currencyToFocus.value = false
          if (
            currency === form.currencyTo &&
            (!isSameCurrencySupported ||
              (isSameCurrencySupported && !isSameCurrencyAvailable.value))
          ) {
            //switch
            const currencyToSwitched = sendMoneyStore.getCurrencyFrom
            sendMoneyStore.form.currencyFrom = currency
            sendMoneyStore.form.currencyTo = currencyToSwitched
          }

          sendMoneyStore.form.currencyFrom = currency

          if (sendMoneyStore.form.shouldCalcAmountFrom) {
            sendMoneyStore.form.amountFrom = null
          }
          await calculator.exec()
        },
      }),
      amountTo: computed({
        get: () => sendMoneyStore.getAmountTo(false),
        set: async (amount) => {
          sendMoneyStore.form.shouldCalcAmountFrom = true
          sendMoneyStore.form.amountTo = amount
          await calculator.exec()
        },
      }),
      currencyTo: computed({
        get: () => sendMoneyStore.form.currencyTo,
        set: async (currency) => {
          if (currency === sendMoneyStore.form.currencyTo) return

          currencyToFocus.value = true
          if (
            currency === form.currencyFrom &&
            (!isSameCurrencySupported ||
              (isSameCurrencySupported && !isSameCurrencyAvailable.value))
          ) {
            //switch
            const currencyFromSwitched = sendMoneyStore.form.currencyTo
            sendMoneyStore.form.currencyTo = currency
            sendMoneyStore.form.currencyFrom = currencyFromSwitched
          }

          sendMoneyStore.form.currencyTo = currency

          if (!sendMoneyStore.form.shouldCalcAmountFrom) {
            sendMoneyStore.form.amountTo = null
          }

          await calculator.exec()
        },
      }),
      paymentMethod: computed({
        get: () => {
          let paymentMethodStore = sendMoneyStore.form.paymentMethod
          notifyPropertyChanged(PROPERTY_CHANGED.PAYMENT_METHOD)
          return paymentMethodStore
        },
        set: async (method) => {
          // no calculate needed since we already have the quotes
          sendMoneyStore.setPaymentMethodAction(method)
          // TODO, clean this up, we can do this much smarter, especially with recent i18n changes
          sendMoneyStore.setPaymentMethodName({
            paymentMethodText: `${$t(getTextKey(method)).value}`,
          })
          const selectedPaymentMethod = paymentMethods.value.find(
            (paymentMethod) => paymentMethod.value === method
          )
          sendMoneyStore.setPaymentMethodType(selectedPaymentMethod)
        },
      }),
      deliveryMethod: computed({
        get: () => {
          let deliveryMethodStore = sendMoneyStore.form.deliveryMethod
          notifyPropertyChanged(PROPERTY_CHANGED.DELIVERY_METHOD)
          return deliveryMethodStore
        },
        set: async (method) => {
          // no calculate needed since we already have the quotes
          sendMoneyStore.setDeliveryMethodAction(method)
        },
      }),
    })

    const initQuoteScreen = async (shouldGetCurrencies = true) => {
      // if recipient and customer currency are the same
      if (
        form.currencyFrom === form.currencyTo &&
        (!isSameCurrencySupported || !isSameCurrencyAvailable.value)
      ) {
        const favoriteCurrency = sendMoneyStore.getFirstFavoriteCurrency(form.currencyTo)
        sendMoneyStore.form.currencyFrom = favoriteCurrency.value
      }

      //hide verification step at the beginin
      sendMoneyStore.changeVerificationVisibility(false)

      //reset the duplicated warning as soon as we land on the page
      sendMoneyStore.hideDuplicateQuoteWarning()
      // Tell the store which step we are on
      sendMoneyStore.setActiveStep(router.currentRoute.path)

      if (form.countryTo) {
        // Set up the calc, use transactions or form state etc
        await sendMoneyStore.initCalculator()

        //if form has the country it will fethc available currencies
        if (shouldGetCurrencies) {
          await sendMoneyStore.setAvailableCurrencies(form.countryTo)
        }
        // if it does have country but nt currency to, we get the default currency
        if (!form.currencyTo) {
          sendMoneyStore.setCurrencyToForCountry(form.countryTo)
        }
      }

      isCountryToLoading.value = false

      if (sendMoneyStore.getCountryTo) {
        validation.$touch()
        await calculator.exec()
      } else {
        showQuoteScreen.value = false
      }

      if (form.paymentMethod) {
        const selectedPaymentMethod = paymentMethods.value.find(
          (paymentMethod) => paymentMethod.value === form.paymentMethod
        )
        sendMoneyStore.setPaymentMethodType(selectedPaymentMethod)
      }
    }
    // INIT
    onBeforeMount(async () => {
      sendMoneyStore.form.transferType = TRANSFER_TYPE.SEND_MONEY_FLOW
      if (isCorporateAccount.value) {
        sendMoneyStore.form.deliveryMethod = DELIVERY_METHODS.BANK_ACCOUNT
      }
      //no need to trigger all the quotes calls if we don't have a country to
      if (sendMoneyStore.getCountryTo) {
        focusAmountFrom()
        await initQuoteScreen()
        sendQuoteAccessedSegment()
        if (sendMoneyStore.form.sendAgain || availableCurrencies.value?.length === 0) {
          await sendMoneyStore.setAvailableCurrencies(form.countryTo)
        }
      } else {
        showQuoteScreen.value = false
        isCountryToLoading.value = false
      }
    })

    const sendQuoteAccessedSegment = () => {
      if (form.countryTo) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.QUOTE_ACCESSED,
          traits: {
            destinationCountry: form.countryTo,
            location: SEGMENT_LOCATIONS.SEND_MONEY,
          },
        })
      }
    }

    if (!form.amountFrom) {
      form.amountFrom = sendMoneyStore.getDefaultAmountFrom()
    }

    const hasServerError = computed({
      //Generic dismissible AppAlert server error
      get: () => !calculator.loading && calculator.error && calculator.error?.status >= 500,
      set: (value) => (calculator.error.status = value),
    })

    const validation = useValidation({
      countryTo: {
        $value: toRef(form, 'countryTo'),
        valueRequired,
      },
      amountFrom: {
        $value: toRef(form, 'amountFrom'),
        isInvalid: {
          $validator() {
            return !sendMoneyStore.form.quoteErrorFrom
          },
          //use custom component in order to display the message / workaround to invalidate the field
          $message: ' ',
        },
        required: {
          $validator(v) {
            return sendMoneyStore.form.shouldCalcAmountFrom || form.amountFrom
          },
          $message: $t('PageSendMoneyAmount.AmountFromRequired').value,
        },
      },
      currencyFrom: {
        $value: toRef(form, 'currencyFrom'),
        valueRequired,
      },
      amountTo: {
        $value: toRef(form, 'amountTo'),
        isInvalid: {
          $validator() {
            return !sendMoneyStore.form.quoteErrorTo
          },
          $message: computed(() => sendMoneyStore.form.quoteErrorTo),
        },
        required: {
          $validator(v) {
            return !(sendMoneyStore.form.shouldCalcAmountFrom && !form.amountTo)
          },
          $message: $t('PageSendMoneyAmount.AmountToRequired').value,
        },
      },
      currencyTo: {
        $value: toRef(form, 'currencyTo'),
        valueRequired,
      },
      paymentMethod: {
        $value: toRef(form, 'paymentMethod'),
        valueRequired,
      },
      deliveryMethod: {
        $value: toRef(form, 'deliveryMethod'),
        valueRequired,
      },
      totalAmount: {
        $value: computed(() => sendMoneyStore.getTotalAmount(false)),
        minAmount: {
          $validator(value) {
            return value > 0
          },
          $message: 'Invalid Min Amount',
        },
      },
    })

    const rate = computed(() => {
      return sendMoneyStore.getRate(true)
    })

    const expiryTime = computed(() => {
      return sendMoneyStore.form.expiryTime
    })

    const topCurrencies = computed(() => resourcesStore.getTopCurrencies)
    const sameCurrencyFromTradingText = ref('')
    const sameCurrencyToTradingText = ref('')

    const currenciesFrom = computed(() => {
      const currencies = sendMoneyStore.getCombinedAvailableCurrenciesFrom

      let availableFromCurrencies = sendMoneyStore.getAvailableCurrenciesFrom
      const selectedCurrency = availableFromCurrencies.find(
        (currency) => currency.value === form.currencyTo
      )

      if (selectedCurrency?.sameCurrencySupported && isSameCurrencySupported) {
        const currency = selectedCurrency.value
        sameCurrencyFromTradingText.value = $t('PageSendMoneyAmount.DropdownSameOptionFromLable', {
          currency,
        }).value
        const currencyList = currencies.filter((currency) => currency.value !== form.currencyTo)
        currencyList.unshift(selectedCurrency)
        return currencyList
      }

      return currencies
    })

    const currenciesTo = computed(() => {
      const currencies = sendMoneyStore.getCombinedAvailableCurrenciesTo

      let availableToCurrencies = sendMoneyStore.getAvailableCurrenciesTo

      const selectedCurrency = availableToCurrencies.find(
        (currency) => currency.value === form.currencyFrom
      )

      if (selectedCurrency?.sameCurrencySupported && isSameCurrencySupported) {
        const currency = selectedCurrency.value
        sameCurrencyToTradingText.value = $t('PageSendMoneyAmount.DropdownSameOptionToLable', {
          currency,
        }).value

        const currencyList = currencies.filter((currency) => currency.value !== form.currencyFrom)
        currencyList.unshift(selectedCurrency)
        return currencyList
      }
      return currencies
    })

    //==== Regions In order of appearance ====

    //region ==== Countries
    const countries = computed(() => sendMoneyStore.getCombinedCountries)
    //endregion

    //region ==== From
    const amountFromLoading = computed(
      () => form.amountTo > 0 && calculator.loading && shouldCalcAmountFrom.value
    )
    const shouldCalcAmountFrom = computed(() => sendMoneyStore.form.shouldCalcAmountFrom)
    //endregion

    //region ==== SendAmountUSD
    const sendAmountUSD = computed(() => sendMoneyStore.getSendAmountUSD(false))
    //endregion

    //region ==== Rate
    const shouldShowRate = computed(() => !validation.$anyInvalid || validation.$anyDirty)
    //endregion

    //region ==== To
    const amountToLoading = computed(
      () => form.amountFrom > 0 && calculator.loading && !shouldCalcAmountFrom.value
    )
    //endregion

    //region ==== Payment Method

    const changePaymentMethod = async () => {
      notifyPropertyChanged(PROPERTY_CHANGED.PAYMENT_METHOD)
    }

    const deliveryTime = computed(() => {
      const selectedPaymentMethod = paymentMethods.value.find(
        (paymentMethod) => paymentMethod.value === form.paymentMethod
      )
      if (!selectedPaymentMethod) {
        return '-'
      }
      return selectedPaymentMethod.quote.time || '-'
    })

    //region ==== Destination Country
    const showQuoteScreen = ref(true)

    const quoteScreenLoading = ref(false)

    const onCountryToSelected = async () => {
      quoteScreenLoading.value = true
      changeDestinationCountry()
      //create a litle timeout before the watch triggers
      setTimeout(() => {
        showQuoteScreen.value = true
      }, 700)

      await initQuoteScreen()
      //adding loading state for the select country button
      quoteScreenLoading.value = false
      sendQuoteAccessedSegment()
    }
    const changeDestinationCountry = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.DESTINATION_COUNTRY_SELECTED,
        traits: {
          location: SEGMENT_LOCATIONS.SEND_MONEY,
          destinationCountry: sendMoneyStore.form.countryTo,
        },
      })
    }
    //region ==== Delivery Method
    let isDeliveryMethodDropdownOpen = ref(false)

    const openDeliveryMethodsDropdown = () => {
      if (!isDeliveryMethodDropdownOpen.value) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.QUOTE_PAYOUT_METHOD_SELECTION_STARTED,
          traits: {
            senderCountry: senderCountry,
            destinationCountry: form.countryTo,
            isCashPickupAvailable: sendMoneyStore.isDeliveryMethodAvailable(
              deliveryMethods.value,
              'CashPayout'
            ),
            isBankDepositAvailable: sendMoneyStore.isDeliveryMethodAvailable(
              deliveryMethods.value,
              'BankAccount'
            ),
            isWalletDeliveryAvailable: sendMoneyStore.isDeliveryMethodAvailable(
              deliveryMethods.value,
              'MobileWallet'
            ),
            location: SEGMENT_LOCATIONS.QUOTE,
          },
        })
        isDeliveryMethodDropdownOpen.value = true
      }
    }

    const deliveryOnBlur = () => {
      if (isDeliveryMethodDropdownOpen.value) {
        setTimeout(() => {
          isDeliveryMethodDropdownOpen.value = false
        }, 200)
      }
    }

    const changeDeliveryMethod = () => {
      const deliveryMethod = sendMoneyStore.form.deliveryMethod
      const senderCountry = authStore.userProfile.country

      notifyPropertyChanged(PROPERTY_CHANGED.DELIVERY_METHOD)

      analyticsStore.track({
        event: SEGMENT_EVENTS.QUOTE_PAYOUT_METHOD_SELECTED,
        traits: {
          senderCountry: senderCountry,
          destinationCountry: form.countryTo,
          payoutMethod: getSegmentPayoutMethod(deliveryMethod),
          location: SEGMENT_LOCATIONS.QUOTE,
        },
      })
    }

    const paymentMethods = computed(() =>
      sendMoneyStore.form.paymentMethods.map((method) => {
        if (method.value === PAYMENT_METHODS.FUNDS_ON_BALANCE) {
          return { ...method, isAvailable: true }
        }
        return method
      })
    )

    const shouldShowPaymentMethodModal = ref(false)
    const showPaymentMethodModal = () => (shouldShowPaymentMethodModal.value = true)
    //endregion

    //filter out funds as elivery method in SMF
    const deliveryMethods = computed(() =>
      sendMoneyStore.form.deliveryMethods.filter((pm) => pm.value !== 'FundsOnBalance')
    )

    const shouldShowDeliveryMethodModal = ref(false)
    const showDeliveryMethodModal = () => (shouldShowDeliveryMethodModal.value = true)
    //endregion

    //region ==== Fee
    const shouldShowFee = computed(() => !validation.$anyInvalid || validation.$anyDirty)
    //endregion

    //region ==== Total
    const shouldShowTotalAmount = computed(() => !validation.$anyInvalid || validation.$anyDirty)
    //endregion

    // When the countryTo changes, get the list of available currencies for it
    watch(
      () => form.countryTo,
      (value) => {
        if (value) {
          countryToTemp = value
          //we trigger the watch only if we are on the quote screen not on the country selection
          if (showQuoteScreen.value) {
            sendMoneyStore.setAvailableCurrencies(value)
          }
        }
      }
    )

    //When the Pyament method changed the segment event fires
    watch(
      () => form.paymentMethod,
      (newVal, oldVal) => {
        if (oldVal) {
          analyticsStore.track({
            event: SEGMENT_EVENTS.QUOTE_PAYMENT_METHOD_CHANGED,
            traits: {
              old_method: oldVal,
              new_method: newVal,
              display_methods: sendMoneyStore.form.paymentMethods
                .filter((paymentmethod) => paymentmethod.isAvailable)
                .map((x) => x.value),
            },
          })
        }
      }
    )
    function notifyPropertyChanged(property) {
      if (summaryEdit.value && property.counter > 1) {
        if (PROPERTY_CHANGED.PAYMENT_METHOD.name === property.name) {
          useFakeActiveStep = true
          fakeActiveStep = '/send-money/recipient'
        } else {
          summaryEdit.value = false
          sendMoneyStore.setActiveStep('/send-money')
          sendMoneyStore.form.isFromRecipient = true
        }
      }
    }
    let useFakeActiveStep = false
    let fakeActiveStep = '/send-money'

    //region ==== Submit
    const submit = reactive(
      usePromiseLazy(async (callBackFunction = null) => {
        const user = authStore.userProfile
        const kycRefreshCorporateStore = useKYCRefreshCorporateStore()
        if (authStore.isCorporateAccount && kycRefreshCorporateStore.isOverdue) {
          kycRefreshCorporateStore.showModal({ isBlockingTransaction: true })
          return
        }

        if (form.paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE) {
          isRedirectToQuickTransferModalOpen.value = true
          return
        }
        if (useFakeActiveStep) {
          sendMoneyStore.setActiveStep(fakeActiveStep)

          //if there is anything changed we notify the backend and we regain the payment methods available
          if (
            !form.deliveryMethod === DeliveryMethod.CashPickup.enumName &&
            !form.paymentMethod === PaymentMethod.BankTransfer.enumName
          ) {
            await sendMoneyStore.calculate({ summary: true })
          }
        } else if (summaryEdit.value) {
          router.go(-1)
          return
        } else if (!sendMoneyStore.form.isFromRecipient && !sendMoneyStore.form.sendAgain) {
          sendMoneyStore.form.recipient = null
        }

        if (typeof callBackFunction === 'function') {
          callBackFunction()
        }

        const userProfiles = await authStore.getUserProfiles()
        const selectedProfile = userProfiles.find((profile) => profile.id === user.customer.id)

        if (sendMoneyStore.duplicatedQuoteFound) {
          sendMoneyStore.trackshowDuplicateQuoteWarning()
          return
        }

        if (selectedProfile.restricted) {
          accountIsRetricted.value = true
          return
        }

        try {
          const activePromotion = promotionStore.activePromotion
          let promoApplied = false
          let promoCode = ''
          let promoName = ''

          if (activePromotion) {
            promoApplied = activePromotion.promotionCode.valid
            promoCode = activePromotion.promotionCode.code
            promoName = activePromotion.name
          }

          // Segment
          analyticsStore.track({
            event: SEGMENT_EVENTS.QUOTE_CONFIRMED,
            traits: {
              destinationCountry: sendMoneyStore.form.countryTo,
              payoutMethod: getSegmentPayoutMethod(sendMoneyStore.form.deliveryMethod),
              payout_amount: sendMoneyStore.form.amountTo,
              payout_currency: sendMoneyStore.form.currencyTo,
              send_amount: sendMoneyStore.form.amountFrom,
              send_currency: sendMoneyStore.form.currencyFrom,
              sendMethod: getSegmentPaymentMethod(sendMoneyStore.form.paymentMethod),
              sender_country: authStore.userProfile?.country,
              sameCurrency: form.currencyTo === form.currencyFrom,
              promoApplied,
              promoCode,
              promoName,
            },
          })

          analyticsStore.gtmTrackVariables({
            variables: {
              Dealing_SellAmount: sendMoneyStore.form.amountFrom,
              Dealing_SellCcy: sendMoneyStore.form.currencyFrom,
              Dealing_BuyAmount: sendMoneyStore.form.amountTo,
              Dealing_BuyCcy: sendMoneyStore.form.currencyTo,
            },
          })

          let fetchedFields = await recipientsStore.getFields({
            id: authStore.userProfile.customer.id,
            country: sendMoneyStore.form.countryTo,
            currency: sendMoneyStore.form.currencyTo,
            isBusiness: false,
          })

          await sendMoneyStore.calculatorGetRecipients()

          sendMoneyStore.setConfirmedRate()

          if (!summaryEdit.value) {
            // call the basic transfer already so we hopefully don't have to wait for it later
            await fetchTransferBasicCall(true)
            sendMoneyStore.showVerifyNotSuppliedModal = true
          }

          sendMoneyStore.goToNextStep()
        } catch (ex) {
          appStore.logException('Exception during submitting check prices', ex)
          ex.toString = null

          //todo-check-again
          if (ex.length > 0) {
            if (ex.find((error) => error.reasonCode == 'RECIPIENT001')) {
              currencyNotAllowed.value = true
            } else {
              appStore.messageBoxGenericError()
            }
          }
        }
      })
    )

    //endregion

    const senderCountry = authStore.userProfile?.country

    const getFlag = (option) => {
      let flag = null
      if (option) {
        flag = 'Flag' + option.substring(0, option.length - 1)
      }
      if (flag === 'FlagKV') {
        flag = 'FlagCV'
      }
      return EuronetFlags[flag] || null
    }

    const formatCurrencyWithSymbol = (currency = 'USD', value = 0, locale) => {
      if (currency === 'CAD') {
        return `$${value.toFixed(2)}`
      }

      return formatCurrencyToParts(currency, value, locale).reduce(
        (acc, item) => acc + item.value,
        ''
      )
    }

    let transferCallPromise = null

    const fetchTransferBasicCall = async (force = false) => {
      if (isCorporateAccount.value) {
        if (!transferCallPromise) {
          transferCallPromise = sendMoneyStore.sendTransfer({
            stage: TRANSFER.STAGES.BASIC,
          })
        } else {
          await transferCallPromise
        }
      }
      if (force) {
        sendMoneyStore.sendTransfer({ stage: TRANSFER.STAGES.BASIC })
      }
    }

    return {
      tag,
      $t,
      rate,
      expiryTime,
      activeStep,
      amountFromLoading,
      amountToLoading,
      calculator,
      changeDeliveryMethod,
      changePaymentMethod,
      changeDestinationCountry,
      countries,
      currenciesFrom,
      currenciesTo,
      topCurrencies,
      deliveryMethods,
      form,
      handleFeeDismiss,
      paymentMethods,
      shouldShowDeliveryMethodModal,
      shouldShowFee,
      shouldShowPaymentMethodModal,
      shouldShowRate,
      shouldShowTotalAmount,
      showDeliveryMethodModal,
      showPaymentMethodModal,
      submit,
      userLocale,
      validation,
      hasServerError,
      getFlag,
      fees,
      transferFee,
      includedFee,
      paymentMethodFee,
      currencyFrom,
      formatCurrencyWithSymbol,
      showFeeInfo,
      feeInfoDialog,
      DeliveryMethod,
      shouldCalcAmountFrom,
      focusedAmountFrom,
      focusedAmountTo,
      focusAmountFrom,
      blurAmountFrom,
      focusAmountTo,
      blurAmountTo,
      mouseEvent,
      summaryEdit,
      accountIsRetricted,
      senderCountry,
      isAmountRounded,
      helperText,
      showQuoteScreen,
      onCountryToSelected,
      isCountryToLoading,
      isSameCurrencySupported,
      sameCurrencyFromTradingText,
      sameCurrencyToTradingText,
      openDeliveryMethodsDropdown,
      deliveryOnBlur,
      isCorporateAccount,
      deliveryTime,
      isBranded,
      quoteScreenLoading,
      PaymentMethod,
      currencyNotAllowed,
      shouldUsePayTo,
      promotionStore,
      availableCurrencies,
      PAYMENT_METHODS,
      isRedirectToQuickTransferModalOpen,
      useBankLogos,
    }
  },
}
</script>
<style scoped>
::v-deep .country-name-after-icon {
  text-wrap: wrap;
}

::v-deep .selected-value > .country-item > .country-name-after-icon {
  text-wrap: nowrap;
}

.country-select {
  @apply pb-4 mb-4;
  border-bottom: 1px solid rgba(243, 244, 246, 1);
  ::v-deep .country-item {
    @apply text-sm font-medium;
  }
  ::v-deep .country-name-before-icon {
    @apply text-blue-text;
  }
}

::v-deep {
  .input-amount-currency {
    &.highlight > .input > .input-wrapper {
      border-radius: var(--theme-input-input-border-radius, theme('borderRadius.lg'));

      .container-left {
        border-top-left-radius: var(--theme-input-input-border-radius, theme('borderRadius.lg'));
        border-bottom-left-radius: var(--theme-input-input-border-radius, theme('borderRadius.lg'));
      }
    }
  }
}

::v-deep .totalListItem {
  @apply pt-4;
  border-top: 0.5px solid rgb(217, 212, 212);
}
.send-money-amount {
  --app-input-amount-currency-dropdown-width: 120px;
}

/* highlight set the border to the currency amount input based on the property */
::v-deep .highlight > .input > .input-wrapper {
  padding: 2px;
  @apply rounded-lg;
  box-shadow: var(--theme-highlight-input-wrapper, inset 0 0 0 3px theme('colors.orange.default'));
}

::v-deep .highlight .container-left {
  border-radius: 0.4rem 0px 0px 0.4rem;
}
::v-deep .highlight .container-right {
  margin-right: -1px;
}

/* if the element is higlighted but we are focusing any element within we get rid of the border so single elements inside can be highlighted*/
::v-deep .highlight > .input > .input-wrapper:focus-within {
  @apply border-none shadow-none;
}

.send-money-amount-currency {
  ::v-deep .selected-value {
    @apply flex;
  }

  .country-flag {
    @apply mr-2;
  }
}
.rate {
  @apply border-b-0 -mt-6; /*Not sure whey we need to offset bottom*/

  ::v-deep .description-list-item-definition {
    @apply max-w-full;
  }
  ::v-deep .description-list-item-term-title {
    @apply text-sm;
    @apply font-medium;
    display: flex;
    align-items: center;
  }
}
.fees {
  ::v-deep .description-list-item-term-title {
    @apply text-sm;
    @apply font-medium;
  }
}
.transfer-total {
  ::v-deep .description-list-item {
    flex-direction: column;
    text-align: left;
    white-space: nowrap;
  }

  ::v-deep .description-list-item-term {
    width: 50%;

    .description-list-item-term {
      width: 100%;
    }
  }

  ::v-deep .description-list-item-term-title {
    width: 100%;
  }

  ::v-deep .description-list-item-definition {
    width: 50%;
  }
}
::v-deep .sendFees > .description-list-item-term {
  @apply text-sm;
  @apply font-medium;
}
::v-deep .totalAmount > .description-list-item-term {
  @apply text-base;
  @apply font-medium;
}
::v-deep .totalAmount > .description-list-item-definition {
  @apply text-base;
  @apply font-normal;
}
::v-deep .countrySelect > .input-label {
  @apply text-base;
  @apply font-medium;
}
.currency-input {
  @apply text-base;
  @apply font-normal;
}
::v-deep span.country-name-after-icon {
  @apply text-base;
  @apply font-medium;
}
::v-deep .prime-dropdown > span > div.selected-value {
  @apply text-base;
  @apply font-normal;
}
.fee-info-dialog {
  ::v-deep .dialog--card.card {
    @screen sm {
      @apply rounded-3xl;
    }
  }
  ::v-deep .card-content {
    @apply py-12 px-4 !important;
    @screen sm {
      @apply p-12 !important;
    }
  }
  .card-header {
    @apply px-0 pt-0 pb-8 border-none;
    ::v-deep .card-header-title {
      @apply h-auto leading-none text-lg;
    }
  }
  .description-list {
    @apply border-b-0;
  }
  .description-list-item {
    @apply mb-4;
    ::v-deep .description-list-item-term {
      @apply text-base leading-6 font-medium;
    }
    ::v-deep .description-list-item-definition {
      @apply text-base leading-6 font-normal;
    }
  }
  .button.button--icon {
    @screen sm {
      @apply w-6 h-6 !important;
      &:hover:not(:disabled) {
        @apply bg-transparent;
      }
      ::v-deep .button-inner {
        @apply w-6 h-6;
        @apply text-gray-dark;
        &:hover {
          @apply text-gray-darker;
        }
      }
    }
  }
  .fee-info-bank-transfer {
    display: flex;
    @apply text-secondary-text text-xs leading-4;

    p {
      @apply ml-2;
    }
  }
}

.payment-methods-dropdown {
  ::v-deep {
    .prime-dropdown .p-dropdown-panel .p-dropdown-item .dropdown-option {
      @apply pt-4 pb-4;
    }
  }
}
::v-deep .balances-icon path {
  fill: rgb(0, 108, 224);
}
.methods {
  ::v-deep .input-input .p-dropdown-items-wrapper {
    @apply mt-2;
    box-shadow: 0px 7px 24px rgba(0, 0, 0, 0.15);
    border: none;
  }
  ::v-deep .input-input .p-dropdown-items {
    @apply py-0;

    .p-dropdown-item:hover {
      @apply bg-gray-tertiary;
    }

    .p-highlight {
      background: rgba(250, 251, 253, 1);
    }
  }
  ::v-deep .p-inputwrapper-focus {
    @apply shadow-none;
    border-color: rgba(221, 221, 221, 1);
  }
  .method {
    @apply flex justify-between items-start gap-4;
  }
  .method-title-and-fee {
    @apply flex justify-between items-center;
  }
  .method-icons {
    @apply text-blue;
    .left-icon {
      margin-top: 0.3rem;
    }
    .card-icon {
      position: relative;
      top: 0.1rem;
    }
    .bank-icon {
      height: 24px;
      width: 24px;
      position: relative;
      bottom: 2px;
    }
  }

  .transfer-methods {
    @apply text-primary-text text-sm font-medium leading-5 w-full;
  }
  .transfer-methods-fee {
    @apply flex flex-col justify-end items-end leading-5;
  }
  .transfer-methods-title {
    @apply flex justify-start items-center;

    .checkmark {
      @apply w-5 h-4 ml-2;
      color: rgba(0, 0, 0, 1);
    }
  }
  .method-sub-text {
    @apply text-gray-text text-xs font-normal leading-4 pt-0.5;
    text-wrap: wrap;
  }

  .method-sub-text p {
    @apply text-red-text block break-words;
    white-space: initial;
    max-width: 250px;
  }
  .method-sub-text span {
    @apply text-red-text block break-words whitespace-pre;
    white-space: initial;
    max-width: 250px;
  }

  ::v-deep .dropdown-option-disabled {
    .dropdown-option-container {
      opacity: 1;

      .method-icons,
      .transfer-methods-title {
        opacity: 0.5;
      }
      .method-flag-new {
        opacity: 0.5;
      }
    }
  }
}

::v-deep .xebubble {
  position: fixed;
  /* top: 420px;
  left: 525px; */
}
.bubble {
  height: 80px;
  width: 298px;
  background: black;
  display: block;
  margin: 0 auto;
  border-radius: 6px;
  font-size: 15px;
  text-align: center;
  color: white;
  padding: 4px, 16px, 4px, 16px;
}

.pointer {
  height: 20px;
  width: 20px;
  background: black;
  margin: 0 auto;
  transform: rotate(45deg);
  border-radius: 0 0 12px 0;
  position: relative;
  left: calc(0.5vw - 100px);
  bottom: -10px;
}

.send-money-amount-helper-text {
  ::v-deep .input-helper {
    @apply text-gray-text;
  }
}
.icon-payment-method {
  @apply text-tertiary-text;
}

::v-deep .country-select .p-dropdown-items-wrapper {
  @apply w-full;
}

::v-deep .formatted-input .input .input-wrapper {
  grid-template-columns: [text] auto [dropdown] 134px !important;
}

::v-deep .dropdown-option {
  @apply flex;
}
.option-text {
  @apply flex justify-between w-full;
  @apply font-medium;
}
.flag-new {
  color: var(--theme-color-primary, theme('colors.blue.default'));
}
.method-flag-new {
  @apply text-blue-text;
  @apply py-0.5 px-3;
  @apply bg-blue-lighter;
  border-radius: 100px;
}
::v-deep .dropdown-option-container {
  @apply w-full;
}
.delivery-methods-wraper {
  @apply flex justify-between;
}
.currency-text {
  @apply font-medium;
}
::v-deep .currency-expanded > .currency-input > .input-wrapper > .input-input {
  border-radius: var(--theme-input-input-border-radius, theme('borderRadius.lg')) !important;
}

::v-deep .input-radio-buttons.qt-delivery-method-radio .halo span {
  @apply w-4 h-4 border-text_dark-text;
}
::v-deep .input-radio-buttons.qt-delivery-method-radio .halo {
  @apply w-8 h-8 mr-0;
}

::v-deep input[type='radio'] ~ .halo .input-radio-button:after {
  @apply w-2 h-2 bg-text_dark-text;
}
::v-deep input[type='radio'] ~ .halo:after {
  @apply hidden;
}
::v-deep
  .input-radio-buttons.qt-delivery-method-radio
  input[type='radio']:checked
  ~ .halo
  .input-radio-button {
  @apply border-text_dark-text;
}

::v-deep .qt-delivery-method-radio {
  @apply flex flex-row gap-2;
}

.interac-title {
  @apply flex;
  .interac-icon {
    @apply ml-2;
  }
}
</style>
