<template>
  <InformationAppModal
    icon="crossmark"
    :value="model"
    :button-title="$t('PageActivity.ButtonGotIt').value"
    @close="closeModal"
    @confirm="closeModal"
  >
    <template>
      <h1 class="title">
        {{
          $t('CurrencyNotSupportedModal.HeaderTitle', {
            currencyTo: sendMoneyStore.getCurrencyTo,
          }).value
        }}
      </h1>

      <p>
        {{
          $t('CurrencyNotSupportedModal.Description', {
            currencyTo: sendMoneyStore.getCurrencyTo,
            countryTo: countryToText,
          }).value
        }}
      </p>
    </template>
  </InformationAppModal>
</template>

<script>
import { useVModel } from '@oen.web.vue2/ui'

import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal.vue'
import { useSendMoneyStore, useI18nStore } from '@galileo/stores'

import { computed } from '@vue/composition-api'

export default {
  name: 'CurrencyNotSupportedModal',
  components: {
    InformationAppModal,
  },
  props: {
    value: {
      type: Boolean,
    },
  },
  emits: ['closeModal'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()
    const { model } = useVModel(props, emit)

    const countryToText = computed(() => sendMoneyStore.getCountryToText)

    const closeModal = () => {
      emit('closeModal')
    }

    return {
      model,
      $t,
      closeModal,
      sendMoneyStore,
      countryToText,
    }
  },
}
</script>

<style scoped>
h1 {
  @apply text-2xl font-semibold flex justify-center;
}

p {
  @apply px-4 text-sm flex justify-center text-center;
}
</style>
