<template>
  <AppOverlay
    class="davor"
    v-model="visible"
    :dismissible="dismissible"
    :transition-type="transitionType"
    @showing="onShowing"
    @show="onShow"
    @shown="onShown"
    @hiding="onHiding"
    @hide="onHide"
    @hidden="onHidden"
  >
    <template v-if="$slots.header" #header>
      <slot name="header" :dismiss="dismiss" />
    </template>
    <slot />
    <template v-if="$slots.footer" #footer>
      <slot name="footer" />
    </template>
  </AppOverlay>
</template>

<script>
import { computed } from '@vue/composition-api'



import { useMediaQuery, AppOverlay, AppCard, useAppOverlay } from '@oen.web.vue2/ui'

export default {
  name: 'AppDialog',
  components: {
    AppOverlay,
    AppCard,
  },
  emits: ['input', 'showing', 'show', 'shown', 'hiding', 'hide', 'hidden'],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, slots }) {
    const mq = useMediaQuery()

    const transitionType = computed(() => {
      if (mq.current === 'xs') {
        return 'slide-up'
      }
      return null
    })

    const {
      dismiss,
      visible,
      onShowing,
      onShow,
      onShown,
      onHiding,
      onHide,
      onHidden,
    } = useAppOverlay(props, emit)

    return {
      dismiss,
      visible,
      transitionType,
      onShowing,
      onShow,
      onShown,
      onHiding,
      onHide,
      onHidden,
      slots,
    }
  },
}
</script>

<style scoped></style>
