<template>
  <information-app-modal
    :value="shouldDisplayUsWireTransferWarningMessage"
    button-title="Continue"
    @close="closeModal"
    @confirm="closeModal"
    icon="hand"
  >
    <h1 class="title">{{ $t('SendMoneyUsWireTransferWarning.TitleText').value }}</h1>
    <!-- <h1 class="title">Payment method updated</h1> -->
    <p>{{ $t('SendMoneyUsWireTransferWarning.MainText', { paymentMethod }).value }}</p>
    <!-- <p>
      This amount is below our $3,000 USD limit for wire transfer so we’ve changed your payment method to {paymentMethod}
    </p> -->
  </information-app-modal>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { useI18nStore } from '@galileo/stores'
import { useSendMoneyStore } from '@galileo/stores/sendMoney'

export default defineComponent({
  components: { InformationAppModal },
  setup() {
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()

    const shouldDisplayUsWireTransferWarningMessage = computed(
      () => sendMoneyStore.shouldDisplayUsWireTransferWarning
    )

    const paymentMethod = computed(() => {
      const paymentMethods = sendMoneyStore.form?.paymentMethods

      let selected = sendMoneyStore.form.paymentMethod
      let selectedMethod = paymentMethods.find((method) => method.value === selected)
      return selectedMethod ? selectedMethod.text : selected
    })

    const closeModal = () => {
      sendMoneyStore.shouldDisplayUsWireTransferWarning = false
    }

    return { shouldDisplayUsWireTransferWarningMessage, closeModal, paymentMethod, $t }
  },
})
</script>

<style></style>
